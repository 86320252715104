import cryptoJs from 'crypto-js';

const IV = "vcmcfuwerkczxvot"
const SK = "49058195125672156889317646787120"

// 암호화
export const encrypt = (text) => {
  const cipher = cryptoJs.AES.encrypt(text, cryptoJs.enc.Utf8.parse(SK), {
    iv: cryptoJs.enc.Utf8.parse(IV),
    padding: cryptoJs.pad.Pkcs7,
    mode: cryptoJs.mode.CBC,
  });

  return encodeURIComponent(cipher.toString());
}

// 복호화
export const decrypt = (encryptedText) => {
  encryptedText = decodeURIComponent(encryptedText)
  const decipher = cryptoJs.AES.decrypt(encryptedText, cryptoJs.enc.Utf8.parse(SK), {
    iv: cryptoJs.enc.Utf8.parse(IV),
    padding: cryptoJs.pad.Pkcs7,
    mode: cryptoJs.mode.CBC,
  })

  return decipher.toString(cryptoJs.enc.Utf8);
}